

















































































































import { computed, defineComponent, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import SolutionTitle from '@/components/SolutionTitle.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'in-use',
  props: ['content'],
  components: { LineSplitter, HorizontalLine, SolutionTitle },
  setup(props) {
    const hasSolutions = computed(
      () => props.content.solutions && props.content.solutions.length > 0
    )

    const hrRef = ref()
    const titleRef = ref()
    const solutionTitleRef = ref()
    const productTitleRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (hrRef.value) {
          TL.add(hrRef.value.getTween(), 'start')
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start+=0.1')
        }

        if (solutionTitleRef.value) {
          solutionTitleRef.value.forEach(el => {
            TL.add(el.getTween(), '>-0.9')
          })
        }

        if (productTitleRef.value) {
          productTitleRef.value.forEach(el => {
            TL.add(el.getTween(), '>-0.9')
          })
        }
      }
    }

    return {
      hasSolutions,
      hrRef,
      titleRef,
      solutionTitleRef,
      productTitleRef,
      animateIn,
    }
  },
})
